@import 'main';
@import 'vars';

// Стили для Шапки ВЗ
.vz-header {
  background-color: $color-white;
  padding: 40px 0;

  .logo-wrap {
    white-space: nowrap;

    .logo {

      img {
        float: left;
        height: auto;
        margin-right: 10px;
        width: 70px; } }

    .logo-title {
      display: inline-block;
      font-family: $headline-font;
      font-size: 16px;

      span {
        font-family: $default-font;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: .6em;
        text-transform: uppercase; } } }

  .header-label {
    color: #999;
    font-size: .85em; }

  .header-content {
    font-size: 1.4em;
    margin-top: .3em;

    a {
      color: #5A5A5A; } }

  .header-phone-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 2em;
    border: 2px solid $accent;
    color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto; } }

// Стили для ВСЕРОСИЙСКОГО ЗАЧЕТА
.zachet-flip-card {

  .item {
    cursor: pointer;
    display: block;
    height: 100%;
    overflow: hidden;
    padding: 10px;
    perspective: 800px;
    position: relative;
    width: 100%;

    .flipper-card {
      height: 100%;
      transform-style: preserve-3d;
      transition: all 1.1s cubic-bezier(.175, .885, .32, 1.275);
      width: 100%;

      .flipper-front,
      .flipper-back {
        @include mt(.3s);
        backface-visibility: hidden;
        border: 1px solid transparent;
        padding: 25px 15px;
        text-align: center;

        .icon {
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 3px 10px rgba(#000, .3);
          color: $accent;
          display: inline-block;
          font-size: 2em;
          height: 70px;
          line-height: 70px;
          position: relative;
          text-align: center;
          width: 70px; }

        p {
          &.num {
            color: #999;
            font-size: 1.5em;
            margin-bottom: 0;
            margin-top: 1.5em; } }

        h3 {
          color: $accent;
          font-family: $bold-font;
          margin-bottom: 1em;
          margin-top: 0; }

        .flip-btn {
          background: transparent;
          border: 0;
          color: $orange;
          font-weight: 700;
          outline: none;

          i {
            font-size: 26px;
            margin-right: 7px; } } }

      .flipper-front {

        &:hover {
          border: 1px solid #ddd; } }

      .flipper-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 630px;
        display: block;
        transform: rotateY(180deg);
        background-color: #f2f2f2; } } } }

.s-rezult {

  img {
    height: auto;
    width: 150px; } }

.media {
  &.discount {

    .media-left {
      padding: 0; }

    .media-body {
      padding-bottom: 10px;
      padding-top: 10px;

      p {
        border-bottom: 2px dashed #f00;
        border-left: 0;
        border-right: 2px dashed #f00;
        border-top: 2px dashed #f00;
        display: inline-block;
        font-size: 14px;
        height: 100%;
        margin: 0;
        padding: .5em 1em .5em 2em;
        width: 100%; } }

    .media-object {
      margin-right: -10px; } } }

// Всегда в конце
@import 'media';
